import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';

import CaseStudyImg from '../../../assets/hp-images/case-study-1.png';
import "./casestudy.css";

const slidesContent = [
    {
        title: "Medical Insights Framework for Medical Affairs Team",
        description: "We created a Medical Insight-to-Action Framework that identified medical insight trends, gaps, implications, and actions aligned with the product's strategic imperatives and critical success factors, enabling monthly tracking. Leveraging ClarityNav, we analyzed multiple data sources to extract this information."
    },
    {
        title: "CNC Indication And Endpoint Prioritization for Clinical Development",
        description: "Using AssetNav, all potential indications were ranked across multiple dimensions, including: Epidemiology, Burden of persistent unmet clinical need/quality-of-life effects, Complexity of standard clinical endpoints, Symptom scores, Likelihood of improvement, On-market and pipeline competitive intensity."
    },
    {
        title: "Maintaining HNSCC* Category Leadership",
        description: "Built a long list of clinical-stage assets under evaluation in HNSCC. Using AssetNav, we selected targets with clinical-level efficacy data and ranked each target based on scientific, clinical, and commercial attractiveness."
    },
    {
        title: "Optimize Portfolio Strategy",
        description: "Using AssetNav, we generated a comprehensive list of asset opportunities and portfolio options. We prioritized 18 programs by applying pre-agreed criteria."
    },
    {
        title: "Business Case Development for a Product in Primary Sjogren’s Syndrome (pSS)",
        description: "AssetNav and other proprietary sources were utilized to identify promising assets in the pipeline. Additionally, a patient-based revenue forecast model was developed to estimate the commercial opportunity for the product."
    },
    {
        title: "Commercial Assessment of a Product in Obese Patients With HFpEF",
        description: "A patient-based revenue forecast was developed for the product. The addressable patient pool was estimated and further adjusted for potential disease overlap between obesity and HFpEF. AssetNav and other proprietary sources were utilized to develop a competitive map for the product. Adoption of the product was estimated using an attribute analysis framework. Multiple business scenarios were developed to assess the impact on the product’s revenue."
    },
    {
        title: "Disease Area Strategy in Rare Endocrine Disorders",
        description: "Initial steps focused on defining the opportunity universe: the client’s long list of indications was validated and augmented using foundational knowledge. A multi-dimensional prioritization framework was developed in collaboration with the cross-functional client team, incorporating both technical and commercial parameters. Using AssetNav, each indication was scored on technical and commercial attractiveness. We provided real-time relative mapping of opportunities across different dimensions."
    },
    {
        title: "Grow Leadership Position In Colorectal Cancer (CRC)",
        description: "Leveraging AssetNav, we built a library of clinical-stage assets under evaluation in CRC, rolled up at the target level. An initial screen was used to select targets with clinical-level efficacy data. AssetNav provided the most recent data readouts and generated comprehensive target profiles. Each target was ranked and short-listed. Using AssetNav’s Scoring Framework, we identified assets available for partnering among the prioritized targets"
    },
    {
        title: "Medical Insights Framework For Cardiovascular Asset",
        description: "Created a Medical Insight-to-Action Framework that identifies medical insight trends, gaps, implications, and actions aligned with the asset’s strategic imperatives and critical success factors, enabling monthly tracking in a Tableau dashboard. Leveraging ClarityNav, we analyzed multiple data sources, including competitive insight flashes, advisory boards, and field medical data."
    },
];

function CaseStudy() {
    return (
        <section className="py-4 py-md-5 py-xl-6 position-relative bg-offwhite">
            <div className="container">
                <div className="row justify-content-center my-4 mb-lg-6">
                    <div className="col-12 col-md-10 col-lg-9 col-xl-8">
                        <p className="mt-4 cs-ttl-sub-text text-center">IT'S TIME TO REIMAGINE HEALTH</p>
                        <h2 className="mt-4 text-3xl colour-black text-center">How we’ve helped our <br />customers</h2>
                    </div>
                </div>
                <div className="row justify-content-between py-xl-6 py-4">
                    <div className="col-12">
                        <Swiper
                            navigation={true}
                            slidesPerView={1}
                            spaceBetween={30}
                            breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                  spaceBetween: 30,
                                },
                                768: {
                                  slidesPerView: 1,
                                  spaceBetween: 30,
                                },
                                1024: {
                                  slidesPerView: 3,
                                  spaceBetween: 60,
                                },
                              }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination, Navigation]}
                            className="case-study-new-swiper swiper-wrapper-container"
                        >
                            {slidesContent.map((slide, index) => (
                                <SwiperSlide key={index}>
                                    <div className="article--card_rounded corner--radius bg-white shadow-md">
                                        <img src={CaseStudyImg} alt='case-syudy-img' className="img-fluid" />
                                        <div className="article--card_details bg-white p-4 element-item card-hgt">
                                            <h3 className="text-xl mb-3 case-study-card-box-ttl">{slide.title}</h3>
                                            <div className="colour-grey link-dark-parent cs-text-sp">
                                                <p className="font--md mb-3 case-study-sub-text">{slide.description}</p>
                                            </div>
                                        </div>
                                        {/* <div className="position-relative; mb-4">
                                            <span className="colour-secondary arrow--link font-bold font--reg pl-4">
                                                {slide.linkText}
                                            </span>
                                        </div> */}
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CaseStudy;