import React, { useState, useEffect } from "react";
import "./ourSolution.css";

import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import AuthService, { tokenValidationApi } from "../../../services/AuthService";
import config from "../../../services/config.js";

import ReactPlayer from 'react-player';

import assetNavTabIconImage from '../../../assets/hp-images/asset-tab-icon-img.png';
import indicationNavTabIconImage from '../../../assets/hp-images/indication-tab-icon-img.png';
import clarityNavTabIconImage from '../../../assets/hp-images/clarity-tab-icon-img.png';
import quantNavTabIconImageImage from '../../../assets/hp-images/quant-tab-icon-img.png';

import assetNavVideoSrc from '../../../assets/hp-images/solutions/AssetNav.mp4';
import indicationNavVideoSrc from '../../../assets/hp-images/solutions/IndicationNav.mp4';
import clarityNavVideoSrc from '../../../assets/hp-images/solutions/ClarityNav.mp4';
import quantVideoSrc from '../../../assets/hp-images/solutions/QuantNav.mp4';

const tabs = [
    {
      id: 1,
      name: "AssetNav",
      title: "AssetNav",
      description: "Streamlining resource allocation and strategic decision-making for commercialization teams.",
      details: "Data and insights are central to what we do. With AssetNav, a product pipeline tool, in our InizioNavigator.ai suite, our teams can help you to quickly identify and prioritize assets, to power smarter decision-making.",
      tabIcon: assetNavTabIconImage,
      url: assetNavVideoSrc,
      toolLink: `${process.env.REACT_APP_ASSETNAV_URL}/score-search-selection`,
    },
    {
      id: 2,
      name: "IndicationNav",
      title: "IndicationNav",
      description: "A cutting-edge tool enabling fast search, advanced scoring, and visual aids for prioritization.",
      details: "An innovative tool for indication prioritization, features a quick search function, state-of-the-art scoring module, and relevant visualizations, ensuring efficient and precise execution of indication prioritization.",
      tabIcon: indicationNavTabIconImage,
      url: indicationNavVideoSrc,
      toolLink: `${process.env.REACT_APP_INDICATIONDEV_URL}/indications-selection`,
    },
    {
      id: 3,
      name: "ClarityNav",
      title: "ClarityNav",
      description: "Transforming Medical Affairs Data into Actionable Insights, Unleash the Power of Medical Data",
      details: "A tool in the Inizio Navigator suite, that generates rapid insights on large volumes of Medical Affairs data. It's brand-agnostic and can be customized for clients' specific needs.",
      tabIcon: clarityNavTabIconImage,
      url: clarityNavVideoSrc,
      toolLink: `${process.env.REACT_APP_VOCINSIGHTS_URL}/voc-input`,
    },
    {
      id: 4,
      name: "QuantNav",
      title: "QuantNav",
      description: "Revolutionize Your Survey Data Analysis, Elevate Your Survey Analysis Experience.",
      details: "Quick insights, enhanced data quality, and informed decisions through data mapping, respondent analysis, and more.",
      tabIcon: quantNavTabIconImageImage,
      url: quantVideoSrc,
      toolLink: `${process.env.REACT_APP_QUANT_URL}`,
    },
  ];


function OurSolutions() {
    return (
        <>
            <section class="py-4 py-md-5 py-xl-6 position-relative bg-white">
                <div class="blue-circle-pos d-none d-lg-block rounded-circle bg-secondary-10 bottom-0 left-0 bgCircle-md position-absolute z-3 aos-init aos-animate"></div>
                <div className="container container-index">
                    <div className="row">
                        <div className="col-12 col-md-10 col-lg-9 col-xl-8">
                            <h2 class="text-3xl colour-black">Our Solutions<span class="colour-primary">.</span></h2>
                        </div>
                        <div className="col-12">
                            <CardTabs tabs={tabs} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

function CardTabs({ tabs }) {

    const navigate = useNavigate();
    const [cookies] = useCookies(["inizionavigator"]);
    const UserEmail = cookies.inizionavigator && cookies.inizionavigator.userLoginEmail;

    const [activeTabId, setActiveTabId] = useState(tabs[0].id);
    const activeTab = tabs.find(tab => tab.id === activeTabId);


    const [playing, setPlaying] = useState(false);
    const [showButton, setShowButton] = useState(true);

    const handleNavigation = async (navigationPath, application) => {
        const appMap = {
            AssetNav: "apt",
            IndicationNav: "ipt",
            ClarityNav: "voc",
            QuantNav: "quant",
        };

        const app = appMap[application];
        if (!app) {
            console.error("Unknown application:", application);
            return;
        }

        try {
            await config.post(
                "/api/user-track",
                { email: UserEmail, application: app },
                { headers: { Authorization: `Bearer ${cookies.inizionavigator.token}` } }
            );
            window.location.replace(navigationPath);
        } catch (error) {
            console.error("Navigation error:", error.response?.data || error.message);
            alert("Navigation failed. Please try again later.");
        }
    };

    useEffect(() => {
        async function validateToken(token) {
            try {
                const payload = {
                    token: token,
                };
                const response = await tokenValidationApi(payload);
                if (response?.data?.is_success == true) {
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        }

        async function HandleSSORedirection() {
            if (cookies.inizionavigator && cookies.inizionavigator.token) {
                let tokenValidation = await validateToken(
                    cookies.inizionavigator && cookies.inizionavigator.token
                );

                if (!tokenValidation) {
                    return navigate("/login");
                }
            }
            else {
                navigate("/login");
            }
        }

        HandleSSORedirection();
    }, [cookies?.inizionavigator, navigate]);



    const handlePlayPause = () => {
        setPlaying(!playing);
        setShowButton(!playing);
    };

    const handlePlayerClick = () => {
        setPlaying(true);
        setShowButton(false);
    };

    return (
        <div className="tabs-container py-4">
            <div className="tabs__nav">
                {tabs.map(tab => (
                    <div
                        key={tab.id}
                        className={`tab__item ${activeTabId === tab.id ? "active" : ""}`}
                        onClick={() => setActiveTabId(tab.id)}
                    >
                        <div className="tab_white_box">
                            <div className="tab__icon">
                                <img src={tab.tabIcon} alt="tab-icon" className="img-fluid" />
                            </div>
                            <div className="tab__content">
                                <h4 className="tab__title">{tab.name}</h4>
                                <p className="tab__description">{tab.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="tabs__content position-relative">
                <div className="player-wrapper">
                    <ReactPlayer
                        className="react-player"
                        url={activeTab.url}
                        playing={playing}
                        width="100%"
                        height="100%"
                        controls={true}
                        onPlay={() => setShowButton(false)}
                        onPause={() => setShowButton(true)}
                    />
                    {showButton && (
                        <div className="custom-controls">
                            <button className="play-pause-btn" onClick={handlePlayPause}>
                                {playing ?
                                    <PauseButton />
                                    :
                                    <PauseButton />
                                }
                            </button>
                        </div>
                    )}
                    {playing ? (<div className="video-sec-pill-button">{activeTab.name}</div>) : (<div className="video-sec-pill-button">{activeTab.name}</div>)}

                </div>
                <div className="tab__details">
                    <p>{activeTab.details}</p>
                    <div className="tab__buttons">
                        <button
                            className="btn th-btn-primary default"
                            onClick={() => handleNavigation(activeTab.toolLink, activeTab.name)}
                        >
                            Access the Tool
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function PauseButton() {
    return (
        <svg width="59" height="65" viewBox="0 0 59 65" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M.406 8.953c0-6.877 7.455-11.162 13.39-7.697l40.138 23.43c5.89 3.439 5.89 11.955 0 15.394L13.797 63.51C7.86 66.975.407 62.69.407 55.813V8.953zm49.041 23.43L9.31 8.953v46.86l40.137-23.43z" fill="#00CA9F" /></svg>
    )
}

export default OurSolutions;
