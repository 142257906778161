import "./aboutinizionavigator.css";

function AboutInizioNavigator() {
    const content = {
        title: "About InizioNavigator.ai",
        description: "With the power of our innovative consultant-led and AI-driven data platform, Navigator, we were able to swifty integrate the two large data sets. Integrating ~410 GB data rapidly, Navigator also facilitated the setup of data pipelines to enable a monthly refresh.",
        highlights: [
            "Provides insights from diverse, complex data sources.",
            "Offers customization of insights to meet specific requirements.",
            "Enables quick and confident strategic decision-making.",
            "Enhances collaboration through seamless data sharing and integration."
        ]
    };

    return (
        <section className="about-inizio-module bg-offwhite">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 p-4 p-md-5 p-xl-7 d-flex align-items-center">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="text-3xl about-inizio-ttl">{content.title}</h2>
                                <p className="about-inizio-text">{content.description}</p>
                            </div>
                            <div className="col-12 pb-4">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        {content.highlights.slice(0, 2).map((highlight, index) => (
                                            <div className="d-flex align-items-center mb-3" key={index}>
                                                 <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="-0.212891" y="0.439941" width="24" height="24" rx="12" fill="#D6D5FF" />
                                            <path d="M16.6846 10.2129L10.6846 16.2129C10.6323 16.2654 10.5702 16.307 10.5018 16.3354C10.4335 16.3638 10.3602 16.3784 10.2861 16.3784C10.2121 16.3784 10.1388 16.3638 10.0704 16.3354C10.002 16.307 9.93995 16.2654 9.88769 16.2129L7.26269 13.5879C7.21037 13.5356 7.16887 13.4735 7.14055 13.4051C7.11223 13.3368 7.09766 13.2635 7.09766 13.1895C7.09766 13.1155 7.11223 13.0422 7.14055 12.9739C7.16887 12.9055 7.21037 12.8434 7.26269 12.7911C7.31502 12.7387 7.37714 12.6972 7.4455 12.6689C7.51386 12.6406 7.58714 12.626 7.66113 12.626C7.73513 12.626 7.8084 12.6406 7.87676 12.6689C7.94513 12.6972 8.00725 12.7387 8.05957 12.7911L10.2866 15.0181L15.8886 9.41699C15.9943 9.31132 16.1376 9.25195 16.2871 9.25195C16.4365 9.25195 16.5798 9.31132 16.6855 9.41699C16.7912 9.52266 16.8505 9.66599 16.8505 9.81543C16.8505 9.96487 16.7912 10.1082 16.6855 10.2139L16.6846 10.2129Z" fill="#5D3AEA" />
                                        </svg>
                                                <p className="about-icon-text">{highlight}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-12 col-md-6">
                                        {content.highlights.slice(2).map((highlight, index) => (
                                            <div className="d-flex align-items-center mb-3" key={index + 2}>
                                                 <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="-0.212891" y="0.439941" width="24" height="24" rx="12" fill="#D6D5FF" />
                                            <path d="M16.6846 10.2129L10.6846 16.2129C10.6323 16.2654 10.5702 16.307 10.5018 16.3354C10.4335 16.3638 10.3602 16.3784 10.2861 16.3784C10.2121 16.3784 10.1388 16.3638 10.0704 16.3354C10.002 16.307 9.93995 16.2654 9.88769 16.2129L7.26269 13.5879C7.21037 13.5356 7.16887 13.4735 7.14055 13.4051C7.11223 13.3368 7.09766 13.2635 7.09766 13.1895C7.09766 13.1155 7.11223 13.0422 7.14055 12.9739C7.16887 12.9055 7.21037 12.8434 7.26269 12.7911C7.31502 12.7387 7.37714 12.6972 7.4455 12.6689C7.51386 12.6406 7.58714 12.626 7.66113 12.626C7.73513 12.626 7.8084 12.6406 7.87676 12.6689C7.94513 12.6972 8.00725 12.7387 8.05957 12.7911L10.2866 15.0181L15.8886 9.41699C15.9943 9.31132 16.1376 9.25195 16.2871 9.25195C16.4365 9.25195 16.5798 9.31132 16.6855 9.41699C16.7912 9.52266 16.8505 9.66599 16.8505 9.81543C16.8505 9.96487 16.7912 10.1082 16.6855 10.2139L16.6846 10.2129Z" fill="#5D3AEA" />
                                        </svg>
                                                <p className="about-icon-text">{highlight}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutInizioNavigator;
