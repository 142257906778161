import React, { useState, useEffect } from "react";
import WhatsNewHeader from "../../components/journeyComponents/WhatsNew/WhatsNewHeader";
import Footer from "../../components/journeyComponents/Footer";
import { useCookies } from "react-cookie";
import AuthService from "../../services/AuthService";
import config from "../../services/config";
import { SuccessToast, ErrorToast } from "../../components/uikit/src/components/toast";
import Loader from "../../components/uikit/src/components/Loader";
import "./newspost.css";

import {adminUsers} from "../../constants/constants";

const NewsPost = () => {
    const [cookies, removeCookie] = useCookies(["inizionavigator"]);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const UserEmail = cookies.inizionavigator && cookies.inizionavigator.userLoginEmail;

    useEffect(() => {
        fetchPosts();
    }, []);

    const fetchPosts = async () => {
        try {
            setIsLoading(true);
            const response = await config.get("api/posts", {
                headers: {
                    Authorization: `Bearer ${cookies?.inizionavigator?.token}`,
                },
            });
            setPosts(response.data);
            setIsLoading(false);
        } catch (error) {
            ErrorToast(error?.message || "Error fetching posts.");
            if (error?.response?.status === 401) {
                handleLogout();
            }
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const postData = { title, content };

        try {
            setIsLoading(true);
            const response = await config.post("api/posts", postData, {
                headers: {
                    Authorization: `Bearer ${cookies?.inizionavigator?.token}`,
                },
            });

            if (response.status === 201) {
                SuccessToast("Post saved successfully!");
                setPosts([...posts, response.data]);
                setTitle("");
                setContent("");
            }
            setIsLoading(false);
        } catch (error) {
            ErrorToast(error?.message || "Failed to save post.");
            if (error?.response?.status === 401) {
                handleLogout();
            }
            setIsLoading(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            setIsLoading(true);
            const response = await config.delete(`api/posts/${id}`, {
                headers: {
                    Authorization: `Bearer ${cookies?.inizionavigator?.token}`,
                },
            });

            if (response.status === 200) {
                SuccessToast("Post deleted successfully!");
                setPosts(posts.filter((post) => post.id !== id));
            }
            setIsLoading(false);
        } catch (error) {
            ErrorToast(error?.message || "Failed to delete post.");
            if (error?.response?.status === 401) {
                handleLogout();
            }
            setIsLoading(false);
        }
    };

    const handleLogout = async () => {
        await AuthService.Logout(UserEmail);
        removeCookie("inizionavigator", {
            path: "/",
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
    };

    return (
        <>
            {isLoading && <Loader />}
            <WhatsNewHeader />
            <div className="tabs-container news-post-top-hgt">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <form className="mb-5" onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">
                                        News Title:
                                    </label>
                                    <input
                                        type="text"
                                        id="title"
                                        className="form-control"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="text" className="form-label">
                                        News Post:
                                    </label>
                                    <textarea
                                        id="text"
                                        className="form-control"
                                        rows="8"
                                        value={content}
                                        onChange={(e) => setContent(e.target.value)}
                                        required
                                    ></textarea>
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    Save Post
                                </button>
                            </form>
                        </div>
                        <div className="col-md-9">
                            <table className="table table-bordered table-striped news-table-hgt news-table">
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Title</th>
                                        <th>News</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {posts.map((post, index) => (
                                        <tr key={post.id}>
                                            <td>{index + 1}</td>
                                            <td>{post.title}</td>
                                            <td>{post.content}</td>
                                            <td className="align-middle text-center">
                                                <svg onClick={() => handleDelete(post.id)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash trsh-fill-clr" viewBox="0 0 16 16">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                </svg>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default NewsPost;
