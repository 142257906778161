import React, { useEffect, useRef } from 'react';
import "./ourjourney.css";

const OurJourney = () => {
    const timelineRef = useRef(null);
    const lineProgressRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const timeline = timelineRef.current;
            const progressLine = lineProgressRef.current;
            if (!timeline || !progressLine) return;

            const items = timeline.querySelectorAll('.ag-timeline_item');
            const { innerHeight: windowHeight, scrollY: scrollTop } = window;

            const getItemOffset = (item) => item.querySelector('.ag-timeline-card_point-box').getBoundingClientRect().top + scrollTop;
            const firstItemOffset = getItemOffset(items[0]);
            const lastItemOffset = getItemOffset(items[items.length - 1]);

            progressLine.style.height = `${Math.min(((scrollTop + windowHeight / 2 - firstItemOffset) / (lastItemOffset - firstItemOffset)), 1) * 100}%`;

            items.forEach(item => {
                item.classList.toggle('js-ag-active', scrollTop + windowHeight / 2 >= getItemOffset(item));
            });
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    const timelineData = [
        { product: "AssetNav", year: "2022", desc: "First custom designed use case, is an asset prioritization tool that supports with BD scans for one or more therapeutic areas and powers strategic recommendations." },
        { product: "IndicationNav", year: "2023", desc: "BD&L tool, Drives margin by creating efficiencies on strategic consulting projects focused on prioritizing indications for specific client assets currently in clinical trials" },
        { product: "ClarityNav ", year: "2024", desc: "Help consulting teams Summarize key themes from diverse sources like medical voice of the customer, advisory boards, inquiries, and market research." },
        { product: "QuantNav ", year: "2024", desc: "Streamlines quantitative survey analysis by automating various aspects of data processing analytics and visualization." },
        { product: "Upcoming", year: "2025 & 2026", desc: "Vynamic: Launch-Nav, PDE Reports (STEM), AACT Programme (STEM), (X-Sell), Survey Nav" }
    ];

    let lastYear = null;

    return (
        <section className="module overlap-above module-walkthrough py-4 py-md-5 py-xl-6 position-relative bg-white module--active">
            <div className="overlap-wrapper">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 text-center wysiwyg-spacing" data-aos="fade-up">
                            <h2 className="text-3xl colour-gravity">Our Journey<span className="colour-primary">.</span></h2>
                            <div className="text-md colour-gravity">
                                <p>The journey to patients is a multi-year and resource intensive effort with high commercial stakes at play...</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-10 py-4 py-lg-6 mb-4 mb-md-0" ref={timelineRef}>
                            <section className="ag-section">
                                <div className="ag-format-container">
                                    <div className="ag-timeline">
                                        <div className="ag-timeline_line">
                                            <div className="ag-timeline_line-progress" ref={lineProgressRef}></div>
                                        </div>
                                        <div className="ag-timeline_list">
                                            {timelineData.map(({ product, desc, year }, index) => {
                                                const showYear = year !== lastYear;
                                                lastYear = year; // Update last displayed year
                                                return (
                                                    <div key={index} className="ag-timeline_item">
                                                        <div className="ag-timeline-card_box">
                                                            {showYear && (
                                                                <div className="ag-timeline-card_meta-box">
                                                                    <div className="ag-timeline-card_meta">{year}</div>
                                                                </div>
                                                            )}
                                                            <div className="ag-timeline-card_point-box">
                                                                <div className="ag-timeline-card_point"></div>
                                                            </div>
                                                        </div>
                                                        <div className="ag-timeline-card_item">
                                                            <div className="walkthrough--card--wd walkthrough--card__content col-md-6 d-flex flex-column align-items-start px-md-6">
                                                                {product === 'Upcoming' ? (
                                                                    <div className="logo-ttl-text">
                                                                        <h4 className="mb-0">
                                                                            <span className="overlay-text-ttl overlay-text-ttl-dark">{product}</span>
                                                                        </h4>
                                                                        <p className="navcard-inizio-logo-text">InizioNavigator.ai</p>
                                                                    </div>
                                                                ) : (
                                                                    <div className="logo-ttl-text">
                                                                        <h4 className="mb-0">
                                                                            <span className="overlay-text-ttl overlay-text-ttl-dark">{product.slice(0, -3)}</span>
                                                                            <span className="overlay-text-ttl overlay-text-ttl-blue">{product.slice(-3)}</span>
                                                                        </h4>
                                                                        <p className="navcard-inizio-logo-text">InizioNavigator.ai</p>
                                                                    </div>
                                                                )}
                                                                <div className="walkthrough--card__content text-">
                                                                    <p>{desc}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurJourney;
