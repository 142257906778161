import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import './imageSlider.css';

const sliderContent = [
  {
    title: 'Navigator: Your Integrated Data Solution<font color="#00CA9F">.</font>',
    description:
      'An integrated data platform powered by Generative AI and Machine Learning to aggregate and analyze all types of data.',
    buttonText: 'Explore Advisory Tools',
    imageClass: 'hp-banner-image-one',
  },
  {
    title: 'Unlock Data-Driven Success with Navigator<font color="#00CA9F">.</font>',
    description:
      'Explore how AI is revolutionizing healthcare with actionable insights into HCP information-seeking behaviors and data interpretation strategies.',
    buttonText: 'Explore Advisory Tools',
    imageClass: 'hp-banner-image-two',
  },
  {
    title: 'Transforming Data into Strategic Decisions<font color="#00CA9F">.</font>',
    description:
      'Gain insights, customize solutions, and make confident strategic decisions quickly. Supports seamless, automated deployment processes for efficiency',
    buttonText: 'Explore Advisory Tools',
    imageClass: 'hp-banner-image-three',
  },
];

function ImageSlider() {
  return (
    <Swiper
      spaceBetween={0}
      effect={'fade'}
      grabCursor={true}
      pagination={{ clickable: true }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      modules={[EffectFade, Navigation, Pagination, Autoplay]}
      className="image-swiper"
    >
      {sliderContent.map((slide, index) => (
        <SwiperSlide key={index}>
          <div className="d-flex w-100 hp-banner-bg-fill-gr-clr height-inherit">
            <div className="container relative z-2 align-items-center justify-content-start d-flex">
              <div className="row">
                <div className="col-12 col-md-7 py-5 py-lg-7 py-xl-9 colour-white">
                  <h1 className="mb-4 text-4xl colour-white font-regular" dangerouslySetInnerHTML={{ __html: slide.title }} />
                  <div className="mb-4 text-md colour-white">
                    <p>{slide.description}</p>
                  </div>
                  {/* <div className="d-flex flex-column flex-lg-row align-items-start mb-4">
                    <a href="#" className="btn th-btn-primary default" tabIndex="-1">
                      {slide.buttonText}
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
            <div className={`hp-banner-image-size ${slide.imageClass} bg-img-pos`}></div>
          </div>
        </SwiperSlide>
      ))}
      {/* <div className="scroll--indicator scroll-position-left">
        <span>Scroll</span>
      </div> */}
    </Swiper>
  );
}

export default ImageSlider;
