import React from "react";
import "./CapabilitiesSlider.css";
import PlatformSolutions from '../../../assets/hp-images/platform-solutions.webp';
import DataEngineering from '../../../assets/hp-images/data-engineering.webp';
import AiSolutions from '../../../assets/hp-images/ai-solutions.webp';
import Analytics from '../../../assets/hp-images/analytics.webp';

const capabilitiesData = [
  {
    id: "rad1",
    title: "Platform Solutions",
    imgSrc: PlatformSolutions,
    bgClass: "bg-quaternary",
    imageClass: "accordion--card__image__platform",
    description: "We combine expertise in market research, strategic consulting, and brand alignment to support global life science and healthcare companies throughout their product and patient journeys."
  },
  {
    id: "rad2",
    title: "Data Engineering",
    imgSrc: DataEngineering,
    bgClass: "th-bg-primary",
    imageClass: "accordion--card__image__data",
    description:
      "By blending scientific expertise and creativity, we craft engaging medical communications, deliver medical affairs support, and conduct real-world evidence research for healthcare impact."
  },
  {
    id: "rad3",
    title: "AI Solutions",
    imgSrc: AiSolutions,
    bgClass: "bg-secondary-10",
    imageClass: "accordion--card__image__ai",
    description: "Our global platform leverages AI-driven insights and human-centric strategies to transform healthcare marketing and communications for sustainable growth."
  },
  {
    id: "rad4",
    title: "Analytics",
    imgSrc: Analytics,
    bgClass: "bg-gravity-30",
    imageClass: "accordion--card__image__analytics",
    description: "Through consultation, creative solutions, and advanced technology, we design tailored strategies that drive impactful and lasting change in healthcare."
  }
];

const CapabilitiesSlider = () => (
  <section className="pb-3 pb-lg-5 bg-default capabilities-bg section-up-margin">
    <div className="container">
      <div className="row">
        {capabilitiesData.map((slide, index) => (
          <div className="col-12 col-md-6 col-lg-3" key={slide.id}>
            <div className="article--card_rounded corner--radius bg-white shadow-md mb-md-5">
              <div>
                <img src={slide.imgSrc} alt='case-syudy-img' className="img-fluid" />
              </div>
              <div className="article--card_details bg-white p-4 element-item capabilities-card-hgt">
                <h3 className="text-xl mb-3 capabilities-card-box-ttl">{slide.title}</h3>
                <div className="colour-grey link-dark-parent capabilities-text-sp">
                  <p className="font--md mb-3 capabilities-sub-text">{slide.description}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default CapabilitiesSlider;
