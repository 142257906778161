import React, { useState, useEffect } from "react";

import { useCookies } from "react-cookie";
import AuthService from "../../../services/AuthService";
import config from "../../../services/config";
import { ErrorToast } from "../../../components/uikit/src/components/toast";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import WhatsNewHeader from "./WhatsNewHeader";
import "./whatsnew.css";

function WhatsNew() {
    const [cookies, removeCookie] = useCookies(["inizionavigator"]);
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const UserEmail = cookies.inizionavigator && cookies.inizionavigator.userLoginEmail;
    useEffect(() => {
        fetchPosts();
    }, []);

    const fetchPosts = async () => {
        try {
            setIsLoading(true);
            const response = await config.get("api/posts", {
                headers: {
                    Authorization: `Bearer ${cookies?.inizionavigator?.token}`,
                },
            });
            setPosts(response.data.reverse());
            setIsLoading(false);
        } catch (error) {
            ErrorToast(error?.message || "Error fetching posts.");
            if (error?.response?.status === 401) {
                handleLogout();
            }
            setIsLoading(false);
        }
    };

    const handleLogout = async () => {
        await AuthService.Logout(UserEmail);
        removeCookie("inizionavigator", {
            path: "/",
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
    };

    return (
        <section className="py-4 py-md-5 py-xl-6 position-relative bg-offwhite position-relative whts-new-sec">
            <div className="container container-index">
                <div className="row justify-content-start mb-3 mb-lg-6">
                    <div className="col-12 col-md-10 col-lg-9 col-xl-8">
                        <h2 className="mt-2 text-3xl colour-black">What’s new at InizioNavigator.ai!</h2>
                        <p className="mt-4 ttl-sub-text">Access the most up-to-date news, insights, and essential resources in health and life sciences—all in one place.</p>
                    </div>
                </div>
                <div className="row justify-content-between py-4 py-xl-6">
                    <div className="col-12">
                        <Swiper
                            navigation={true}
                            slidesPerView={1}
                            spaceBetween={60}
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,
                                },
                                768: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                            }}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="whats-new-swiper swiper-wrapper-container"
                        >
                            {posts.map((slide, index) => (
                                <SwiperSlide key={index}>
                                    <div className="article--card_rounded corner--radius bg-white shadow-md article--card--hgt-wd">
                                        <div className="article--card_details bg-white p-4 element-item">
                                            <div className="d-flex align-items-center justify-content-center flex-column mb-4">
                                                <h4 class="mb-0">
                                                    {
                                                        (slide.title === 'IndicationNav' || slide.title === 'QuantNav' || slide.title === 'ClarityNav' || slide.title === 'AssetNav') ?
                                                            <>
                                                                <span class="overlay-text-ttl overlay-text-ttl-dark">{(slide.title).slice(0, -3)}</span>
                                                                <span class="overlay-text-ttl overlay-text-ttl-blue">{(slide.title).slice(-3)}</span>
                                                            </>
                                                            :
                                                            <>

                                                                <span class="overlay-text-ttl overlay-text-ttl-dark">{(slide.title)}</span>
                                                            </>
                                                    }
                                                </h4>
                                                <p class="navcard-inizio-logo-text">InizioNavigator.ai</p>
                                            </div>
                                            <div className="colour-grey link-dark-parent article--card--text--box_hgt">
                                                <p className="colour-black font--md mb-3">{slide.content}</p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhatsNew;