import React, { useState, useEffect } from "react";

import { useNavigate } from 'react-router-dom';

import AuthService from "../../../services/AuthService";
import config from "../../../services/config.js";
import { useCookies } from 'react-cookie';
import { ErrorToast, SuccessToast } from '../../../components/uikit/src/components/toast';
import Loader from '../../../components/uikit/src/components/Loader';

import "./contactus.css";
function ContactUs() {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)

    const [cookies, removeCookie] = useCookies(["inizionavigator"]);
    const UserEmail = cookies.inizionavigator && cookies.inizionavigator.userLoginEmail;
    const UserName = cookies.inizionavigator && cookies.inizionavigator.userLoginName;

    // Define state to get email content for the input TextArea
    const [userEmailContent, setUserEmailContent] = useState("");

    const handleEmailContent = () => {
        // Call email function for RUN API's
        fetchEmailFunc(userEmailContent);
    }

    // Email Function
    const fetchEmailFunc = async (emailContent) => {
        try {
            const body = {
                "content": emailContent
            }
            setIsLoading(true);
            const data = await config.post(`api/mail`, body, {
                headers: {
                    Authorization: `Bearer ${cookies?.inizionavigator?.token}`
                }
            })
            if (data.status === 201) {
                setIsLoading(false);
                SuccessToast('Your Email send successfully!');
                setUserEmailContent("");
            }
        } catch (error) {
            ErrorToast(error?.message || 'Something went wrong!');
            if (error?.response?.status === 401) {
                HandleLogout();
            }
        }
    };
    // #END Email Funtion


    // Handle Logout
    const HandleLogout = async () => {
        await AuthService.Logout(UserEmail)
        removeCookie(`inizionavigator`, {
            path: "/",
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        navigate("/login");
    };

    return (
        <>
            {isLoading && <Loader />}
            <section className="module py-4 py-md-5 py-xl-6 position-relative contact-us-container">
                <div className="overlap-wrapper">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 text-center wysiwyg-spacing aos-init aos-animate" data-aos="fade-up">
                                <h3 className="colour-primary10 text-uppercase text-lg font-bold"></h3>
                                <h2 className="text-3xl colour-gravity text-white">Have any queries or <br /> suggestions?</h2>
                                <div className="text-md colour-gravity text-white">
                                    <p>Send us your queries or suggestions on InizioNavigator.ai tools <br />
                                        and tell us how it can solve your data challenges. </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 col-md-8 col-lg-8 mx-auto">
                                <div className="row">
                                    <div class="mb-3 mt-3 col-6">
                                        <label for="name" class="form-label text-white">Name*</label>
                                        <input type="text" class="form-control" value={UserName} />
                                    </div>
                                    <div class="mb-3 mt-3 col-6">
                                        <label for="email" class="form-label text-white">Select Tool*</label>
                                        <select class="form-select" aria-label="Default select example">
                                            <option value="AssetNav">AssetNav</option>
                                            <option value="IndicationNav">IndicationNav</option>
                                            <option value="ClarityNav">ClarityNav</option>
                                            <option value="QuantNav">QuantNav</option>
                                        </select>
                                    </div>
                                    <div class="mb-3 mt-3 col-12">
                                        <label for="email" class="form-label text-white">Query*</label>
                                        <textarea
                                            placeholder="Type your message here.... "
                                            class="form-control"
                                            rows="5"
                                            value={userEmailContent}
                                            onChange={(e) => setUserEmailContent(e.target.value)}>
                                        </textarea>
                                    </div>
                                    <div class="mb-4 mt-3 col-12 d-flex justify-content-center">
                                        <div className="send-email-btn contact-btn th-btn-primary d-flex" onClick={handleEmailContent}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="21"
                                                height="21"
                                                viewBox="0 0 21 21"
                                                fill="none"
                                            >
                                                <path
                                                    d="M19.25 1.75L9.625 11.375"
                                                    stroke="white"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M19.25 1.75L13.125 19.25L9.625 11.375L1.75 7.875L19.25 1.75Z"
                                                    stroke="white"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            <p>Send Message</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default ContactUs;