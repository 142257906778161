import "./index.css";

function Footer() {
  return (
    <div className="footer-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 d-flex align-items-center justify-between py-4 footer-flex-column-xs">
            <div className="logo-container">
              <svg viewBox="0 0 914 306" fill="none" xmlns="http://www.w3.org/2000/svg" className="logo">
                <path d="M744.3 201.6C741.6 201.6 739.3 202.5 737.4 204.3C735.5 206.1 734.5 208.4 734.5 211.3C734.5 214.2 735.5 216.6 737.4 218.4C739.3 220.3 741.6 221.2 744.3 221.2C747 221.2 749.4 220.3 751.3 218.4C753.2 216.5 754.1 214.2 754.1 211.3C754.1 208.4 753.2 206.1 751.3 204.3C749.4 202.5 747.1 201.6 744.3 201.6Z" fill="#00CA9F" />
                <path d="M807.2 171C802.6 167.1 796.2 165.1 787.9 165.1C779.6 165.1 779.3 165.7 775.1 166.8C770.9 167.9 767.3 169.6 764.4 171.7L770.1 182.7C772.1 181.1 774.4 179.9 777.2 179C780 178.1 782.9 177.6 785.8 177.6C790.1 177.6 793.2 178.5 795.3 180.4C797.4 182.3 798.4 184.9 798.4 188.3H785.8C780.3 188.3 775.8 189 772.3 190.4C768.9 191.8 766.3 193.7 764.8 196.1C763.2 198.5 762.4 201.4 762.4 204.6C762.4 207.8 763.2 210.5 764.8 213C766.4 215.5 768.7 217.5 771.8 218.9C774.8 220.4 778.5 221.1 782.7 221.1C786.9 221.1 791.4 220.2 794.4 218.4C796.5 217.2 798.1 215.5 799.3 213.5V220.3H814.1V189.2C814.1 180.9 811.8 174.8 807.2 170.9V171ZM793.7 208.8C791.6 210.1 789.2 210.7 786.6 210.7C784 210.7 781.7 210.1 780.1 208.9C778.5 207.7 777.7 206.1 777.7 204.1C777.7 202.1 778.4 200.8 779.8 199.5C781.2 198.3 783.7 197.7 787.4 197.7H798.2V203.3C797.3 205.7 795.7 207.6 793.6 208.8H793.7Z" fill="#00CA9F" />
                <path d="M836.2 158.4C839.2 158.4 841.5 157.5 843.3 155.8C845.1 154.1 846 151.9 846 149.3C846 146.7 845.1 144.8 843.3 143.2C841.5 141.6 839.1 140.8 836.2 140.8C833.3 140.8 830.9 141.6 829.1 143.3C827.3 145 826.4 147.1 826.4 149.6C826.4 152.1 827.3 154.2 829.1 155.9C830.9 157.6 833.3 158.4 836.2 158.4Z" fill="#00CA9F" />
                <path d="M844.1 166H828.3V220.5H844.1V166Z" fill="#00CA9F" />
                <path d="M88.3 88.7C87.4 89 86.5 89.2 85.6 89.3H85.2C84.2 89.3 83.3 89 82.5 88.7V109.7C82.5 110.7 83.4 111.6 84.4 111.6H86.6C87.6 111.6 88.5 110.8 88.5 109.7V88.7H88.3Z" fill="#2A034C" />
                <path d="M176.7 106C175.7 106 175.4 105.4 176 104.6L196.3 77.4C196.9 76.6 196.6 76 195.6 76H167.7C166.7 76 165.8 76.8 165.8 77.9V79.3C165.8 80.3 166.6 81.2 167.7 81.2H184.6C185.6 81.2 185.9 81.9 185.3 82.6L166.7 109.8C166.1 110.6 166.4 111.3 167.4 111.3H195.7C196.7 111.3 196.9 110.8 196.1 110.2L191.3 107.3C190.5 106.7 189 106.2 188 106.2H176.6L176.7 106Z" fill="#2A034C" />
                <path d="M128.8 97.5C128.8 98.5 128.2 98.8 127.4 98.2L101.7 76.3C100.9 75.6 100.3 75.9 100.3 77V109.7C100.3 110.7 101.1 111.6 102.1 111.6H104.3C105.3 111.6 106.2 110.8 106.2 109.7V89.9C106.2 88.9 106.8 88.6 107.6 89.2L133.3 111.2C134.1 111.8 134.7 111.5 134.7 110.5V76.9C134.7 75.9 134.2 75.7 133.6 76.5L130 81.3C129.4 82.1 128.9 83.6 128.9 84.6V97.5H128.8Z" fill="#2A034C" />
                <path d="M261.3 88.4C261 87.4 260.8 86.6 260.8 86.6C260.8 86.6 260.4 85.9 259.9 85C259.9 85 258.5 82.5 256.9 80.8C255.2 79.1 253.3 77.8 251.1 76.9C248.9 76 246.5 75.5 244 75.5C241.5 75.5 239.1 76 237 76.9C234.8 77.8 232.9 79.1 231.2 80.8C229.5 82.5 228.2 84.4 227.3 86.6C226.4 88.8 225.9 91.2 225.9 93.7C225.9 96.2 226.4 98.6 227.3 100.7C228.2 102.9 229.5 104.8 231.2 106.5C232.9 108.2 234.8 109.5 237 110.4C239.2 111.3 241.6 111.8 244.1 111.8C246.6 111.8 249 111.3 251.2 110.4C253.4 109.5 255.3 108.1 257 106.5C258.7 104.8 260 102.9 260.9 100.7C261.8 98.5 262.3 96.1 262.3 93.7C262.3 91.3 261.4 88.4 261.4 88.4H261.3ZM256.2 93.7C256.2 95.4 255.9 97 255.3 98.5C254.7 100 253.8 101.3 252.7 102.4C251.6 103.5 250.3 104.4 248.8 105C247.3 105.6 245.7 105.9 244 105.9C242.3 105.9 240.7 105.6 239.2 105C237.7 104.4 236.4 103.5 235.3 102.4C234.2 101.3 233.3 100 232.7 98.5C232.1 97 231.8 95.4 231.8 93.7C231.8 92 232.1 90.4 232.7 88.9C233.3 87.4 234.2 86.1 235.3 85C236.4 83.9 237.7 83 239.2 82.4C240.7 81.8 242.3 81.5 244 81.5C245.7 81.5 247.3 81.8 248.8 82.4C250.3 83 251.6 83.9 252.7 85C253.8 86.1 254.7 87.4 255.3 88.9C255.9 90.4 256.2 92 256.2 93.7Z" fill="#2A034C" />
                <path d="M147.7 109.6C147.7 110.6 148.5 111.5 149.6 111.5H151.8C152.8 111.5 153.7 110.7 153.7 109.6V76.8C153.7 75.8 153.2 75.6 152.5 76.4L149 80.8C148.4 81.6 147.8 83.1 147.8 84.1V109.6H147.7Z" fill="#2A034C" />
                <path d="M214.7 109.6C214.7 110.6 213.9 111.5 212.8 111.5H210.6C209.6 111.5 208.7 110.7 208.7 109.6V76.8C208.7 75.8 209.2 75.6 209.9 76.4L213.4 80.5C214 81.3 214.6 82.8 214.6 83.8V109.6H214.7Z" fill="#2A034C" />
                <path d="M85.4 81.6C87.6092 81.6 89.4 79.8091 89.4 77.6C89.4 75.3908 87.6092 73.6 85.4 73.6C83.1909 73.6 81.4 75.3908 81.4 77.6C81.4 79.8091 83.1909 81.6 85.4 81.6Z" fill="#00CA9F" />
                <path d="M131.4 189L95.3 145H81.4V217.6H98V173.5L134.2 217.6H148V145H131.4V189Z" fill="#424156" />
                <path d="M188 145L155.6 217.6H172.8L179.3 202H212.9L219.3 217.6H236.9L204.4 145H187.8H188ZM184.6 189.3L196.2 161.5L207.7 189.3H184.6Z" fill="#424156" />
                <path d="M271.4 196.9L249.3 145H231.1L262.5 217.6H279.1L310.5 145H293.8L271.4 196.9Z" fill="#424156" />
                <path d="M333.3 145H316.5V217.6H333.3V145Z" fill="#424156" />
                <path d="M369.5 164.5C371.6 162.4 374.1 160.8 377 159.7C379.9 158.6 383.1 158 386.6 158C390.1 158 393.7 158.7 396.8 160C399.9 161.3 402.8 163.4 405.4 166.2L416.2 156.2C412.7 152.1 408.3 149 403.2 146.9C398 144.8 392.3 143.7 385.8 143.7C379.3 143.7 374.7 144.6 369.8 146.4C365 148.2 360.7 150.8 357.1 154.2C353.5 157.6 350.7 161.6 348.8 166.1C346.8 170.7 345.8 175.7 345.8 181.1C345.8 186.5 346.8 191.6 348.8 196.1C350.8 200.7 353.5 204.6 357.1 208C360.7 211.4 364.8 214 369.6 215.8C374.4 217.6 379.7 218.5 385.4 218.5C391.1 218.5 395.8 217.7 401 216.1C406.2 214.5 410.8 212.2 414.7 209.3V179.8H399.4V201.1C398.3 201.7 397.2 202.2 396 202.6C392.9 203.7 389.6 204.2 386.3 204.2C383 204.2 379.7 203.6 376.8 202.5C373.9 201.4 371.4 199.8 369.3 197.7C367.2 195.6 365.6 193.1 364.4 190.3C363.3 187.5 362.7 184.4 362.7 181C362.7 177.6 363.3 174.4 364.4 171.6C365.5 168.8 367.2 166.3 369.3 164.2L369.5 164.5Z" fill="#424156" />
                <path d="M453.3 145L420.9 217.6H438.1L444.6 202H478.2L484.6 217.6H502.2L469.7 145H453.1H453.3ZM449.9 189.3L461.5 161.5L473 189.3H449.9Z" fill="#424156" />
                <path d="M498.1 158.7H521.3V217.6H538.1V158.7H561.3V145H498.1V158.7Z" fill="#424156" />
                <path d="M704.9 194.3C704.9 194.3 704.9 194.3 705 194.3C709.7 192.2 713.3 189.2 715.9 185.3C718.5 181.4 719.7 176.7 719.7 171.4C719.7 166.1 718.4 161.2 715.9 157.3C713.3 153.4 709.7 150.3 705 148.2C700.3 146.1 694.7 145 688.2 145H656.8V217.6H673.6V197.4H688.2C688.5 197.4 688.7 197.4 689 197.4L702.9 217.6H721L704.8 194.3H704.9ZM698.9 180.6C696.3 182.8 692.5 184 687.3 184H673.6V158.7H687.3C692.5 158.7 696.4 159.8 698.9 162C701.5 164.2 702.7 167.3 702.7 171.3C702.7 175.3 701.4 178.3 698.9 180.6Z" fill="#424156" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M604.5 142.9C625.7 142.9 642.9 160.1 642.9 181.3C642.9 202.5 633.2 210.3 622.9 227L604.5 256.6L586.1 227C575.8 210.4 566.1 200.1 566.1 181.3C566.1 162.5 583.3 142.9 604.5 142.9ZM604.5 158.6C616.3 158.6 625.7 168.3 625.7 179.8C625.7 191.3 616.3 201 604.5 201C592.7 201 583.3 191.6 583.3 179.8C583.3 168 592.7 158.6 604.5 158.6Z" fill="#00CA9F" />
              </svg>
            </div>
            <div className="all-rights-reserved">
              &#169; 2025 InizioNavigator.ai. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Footer;