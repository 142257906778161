import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import AuthService, { tokenValidationApi } from "../../../services/AuthService";
import HeaderLogoImg from "../../../assets/hp-images/navigator-logo.png";

function WhatsNewHeader() {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["inizionavigator"]);
    const UserEmail = cookies.inizionavigator && cookies.inizionavigator.userLoginEmail;
    const UserName = cookies.inizionavigator && cookies.inizionavigator.userLoginName;

    const adminUsers = [
        "himanshu.kant@inizio.com",
        "ext-ruchi.nasa@inizio.health",
        "yash.jauhari@inizio.com",
        "ruchika.kaushik@inizio.com"
    ];


    const logoutCallback = async () => {
        await AuthService.Logout(UserEmail)
        removeCookie(`inizionavigator`, {
            path: "/",
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        navigate("/login");
    };


    return (
        <header className="position-fixed header--dropshadow w-100 top-0 navbar--scrolled">
            <div className="main--header bg-white scrolled--nav">
                <div className="container d-flex position-relative">
                    <input type="checkbox" name="check" id="check" />
                    <div className="logo-container">
                        <Link to="/">
                            <img src={HeaderLogoImg} alt="logo" className="logo" />
                        </Link>
                    </div>
                    <div className="nav-btn">
                        <div className="nav-links">
                            <ul>
                                <Link to="/" className="nav-link" style={{ "--i": "1.50s" }}>
                                    <div class="btn contact-btn th-btn-primary">Home</div>
                                </Link>
                                <li className="nav-link" style={{ "--i": "1.8s" }}>
                                    <div className="head-right-icons">
                                        <div className="person-details">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.5 9C16.5 10.1935 16.0259 11.3381 15.182 12.182C14.3381 13.0259 13.1935 13.5 12 13.5C10.8065 13.5 9.66193 13.0259 8.81802 12.182C7.97411 11.3381 7.5 10.1935 7.5 9C7.5 7.80653 7.97411 6.66193 8.81802 5.81802C9.66193 4.97411 10.8065 4.5 12 4.5C13.1935 4.5 14.3381 4.97411 15.182 5.81802C16.0259 6.66193 16.5 7.80653 16.5 9Z" fill="#303438" />
                                                <path d="M3 0C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3L0 21C0 21.7956 0.316071 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V3C24 2.20435 23.6839 1.44129 23.1213 0.87868C22.5587 0.316071 21.7956 0 21 0L3 0ZM21 1.5C21.3978 1.5 21.7794 1.65804 22.0607 1.93934C22.342 2.22064 22.5 2.60218 22.5 3V21C22.5 21.3978 22.342 21.7794 22.0607 22.0607C21.7794 22.342 21.3978 22.5 21 22.5V21C21 19.5 19.5 15 12 15C4.5 15 3 19.5 3 21V22.5C2.60218 22.5 2.22064 22.342 1.93934 22.0607C1.65804 21.7794 1.5 21.3978 1.5 21V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H21Z" fill="#303438" />
                                            </svg>
                                            <div className="person-text-detail">
                                                <p>{UserName}</p>
                                                <p>{UserEmail}</p>
                                            </div>
                                        </div>
                                        <div onClick={() => logoutCallback()} className="logout-icon">
                                            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_d_973_4152)">
                                                    <rect x="2" y="1" width="33" height="33" rx="4" fill="#00ca9f" />
                                                </g>
                                                <g clip-path="url(#clip0_973_4152)">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.75 24.1875C21.75 24.3698 21.6776 24.5447 21.5486 24.6736C21.4197 24.8026 21.2448 24.875 21.0625 24.875H10.0625C9.88016 24.875 9.7053 24.8026 9.57636 24.6736C9.44743 24.5447 9.375 24.3698 9.375 24.1875V11.8125C9.375 11.6302 9.44743 11.4553 9.57636 11.3264C9.7053 11.1974 9.88016 11.125 10.0625 11.125H21.0625C21.2448 11.125 21.4197 11.1974 21.5486 11.3264C21.6776 11.4553 21.75 11.6302 21.75 11.8125V14.5625C21.75 14.7448 21.8224 14.9197 21.9514 15.0486C22.0803 15.1776 22.2552 15.25 22.4375 15.25C22.6198 15.25 22.7947 15.1776 22.9236 15.0486C23.0526 14.9197 23.125 14.7448 23.125 14.5625V11.8125C23.125 11.2655 22.9077 10.7409 22.5209 10.3541C22.1341 9.9673 21.6095 9.75 21.0625 9.75H10.0625C9.51549 9.75 8.99089 9.9673 8.60409 10.3541C8.2173 10.7409 8 11.2655 8 11.8125V24.1875C8 24.7345 8.2173 25.2591 8.60409 25.6459C8.99089 26.0327 9.51549 26.25 10.0625 26.25H21.0625C21.6095 26.25 22.1341 26.0327 22.5209 25.6459C22.9077 25.2591 23.125 24.7345 23.125 24.1875V21.4375C23.125 21.2552 23.0526 21.0803 22.9236 20.9514C22.7947 20.8224 22.6198 20.75 22.4375 20.75C22.2552 20.75 22.0803 20.8224 21.9514 20.9514C21.8224 21.0803 21.75 21.2552 21.75 21.4375V24.1875Z" fill="#FDFDFE" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.7992 18.4868C29.8633 18.4229 29.9141 18.347 29.9487 18.2635C29.9834 18.18 30.0012 18.0904 30.0012 18C30.0012 17.9096 29.9834 17.82 29.9487 17.7365C29.9141 17.653 29.8633 17.5771 29.7992 17.5133L25.6742 13.3883C25.5452 13.2592 25.3701 13.1866 25.1875 13.1866C25.0049 13.1866 24.8298 13.2592 24.7008 13.3883C24.5717 13.5174 24.4991 13.6924 24.4991 13.875C24.4991 14.0576 24.5717 14.2327 24.7008 14.3618L27.6529 17.3125H15.5625C15.3802 17.3125 15.2053 17.3849 15.0764 17.5139C14.9474 17.6428 14.875 17.8177 14.875 18C14.875 18.1824 14.9474 18.3572 15.0764 18.4862C15.2053 18.6151 15.3802 18.6875 15.5625 18.6875H27.6529L24.7008 21.6383C24.5717 21.7674 24.4991 21.9424 24.4991 22.125C24.4991 22.3076 24.5717 22.4827 24.7008 22.6118C24.8298 22.7409 25.0049 22.8134 25.1875 22.8134C25.3701 22.8134 25.5452 22.7409 25.6742 22.6118L29.7992 18.4868Z" fill="#FDFDFE" />
                                                </g>
                                                <defs>
                                                    <filter id="filter0_d_973_4152" x="0" y="0" width="37" height="37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dy="1" />
                                                        <feGaussianBlur stdDeviation="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0588235 0 0 0 0 0.0901961 0 0 0 0 0.164706 0 0 0 0.08 0" />
                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_973_4152" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_973_4152" result="shape" />
                                                    </filter>
                                                    <clipPath id="clip0_973_4152">
                                                        <rect width="22" height="22" fill="white" transform="translate(8 7)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="hamburger-menu-container">
                        <div className="hamburger-menu">
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default WhatsNewHeader;
