import React, { useState, useEffect } from "react";
import Header from "../../components/journeyComponents/Header/Header";
import Footer from "../../components/journeyComponents/Footer";
import ImageSlider from "../../components/journeyComponents/ImageSlider/ImageSlider";
import OurSolutions from "../../components/journeyComponents/OurSolutions/OurSolutions";
import WhatsNew from "../../components/journeyComponents/WhatsNew/WhatsNew";
import Benefits from "../../components/journeyComponents/Benefits/Benefits";
import CaseStudy from "../../components/journeyComponents/CaseStudy/CaseStudy";
import AboutInizioNavigator from "../../components/journeyComponents/AboutInizioNavigator/AboutInizioNavigator";
import Capabilities from "../../components/journeyComponents/Capabilities/Capabilities";
import OurJourney from "../../components/journeyComponents/OurJourney/OurJourney"
import ContactUs from "../../components/journeyComponents/ContactUs/ContactUs";

import { Link as ScrollLink, Element as ScrollElement } from "react-scroll";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";


import { Navigate, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import AuthService, { tokenValidationApi } from "../../services/AuthService";
import config from "../../services/config.js";

function HomePage() {
    const [showScrollButton, setShowScrollButton] = useState(false);
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["inizionavigator"]);
    const UserName =
        cookies.inizionavigator && cookies.inizionavigator.userLoginName;
    const UserEmail =
        cookies.inizionavigator && cookies.inizionavigator.userLoginEmail;
    const handleNavigation = async (navigationPath, application) => {
        let app = '';
        if (application === 'Indication') {
            app = 'ipt';
        } else if (application === 'Asset') {
            app = 'apt';
        } else if (application === 'Clarity') {
            app = 'voc';
        } else if (application === 'Quant') {
            app = 'quant';
        }

        if (navigationPath === "#") {
            navigate(navigationPath);
        } else {
            await config.post('/api/user-track', { email: UserEmail, application: app }, { headers: { 'Authorization': `Bearer ${cookies.inizionavigator.token}` } })
            window.location.replace(navigationPath)
        }
    };
    const HandleLogout = async () => {
        await AuthService.Logout(UserEmail);
        removeCookie(`inizionavigator`, {
            path: "/",
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        navigate("/login");
    };


    useEffect(() => {
        async function validateToken(token) {
            try {
                const payload = {
                    token: token,
                };
                const response = await tokenValidationApi(payload);
                if (response?.data?.is_success == true) {
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        }

        async function HandleSSORedirection() {
            if (cookies.inizionavigator && cookies.inizionavigator.token) {
                let tokenValidation = await validateToken(
                    cookies.inizionavigator && cookies.inizionavigator.token
                );

                if (!tokenValidation) {
                    return navigate("/login");
                }

                //         const isAdmin = await AuthService.IsAdmin(
                //           cookies.inizionavigator && cookies.inizionavigator.email,
                //           cookies.inizionavigator && cookies.inizionavigator.token
                //         );
                //         console.log("🚀 ~ HandleSSORedirection ~ isAdmin:", isAdmin)
                // debugger;
                //         if (isAdmin) {
                //           return navigate("/admin", {
                //             state: {
                //               isAdmin: true,
                //             },
                //           });
                //         }
            }
            else {
                navigate("/login");
            }
        }

        HandleSSORedirection();
    }, [cookies?.inizionavigator, navigate]);

    // #START: Scroll to ID and down to top section 
    // Scroll button active after scroll
    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        // Change 200 to the number of pixels at which you want to show the button
        if (scrollPosition > 200) {
            setShowScrollButton(true);
        } else {
            setShowScrollButton(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    // #END: Scroll to ID and down to top section 

    const isAdmin =
        cookies.inizionavigator && cookies.inizionavigator.isAdmin;

    if (isAdmin) {
        return <Navigate to="/admin" />;
    }

    return (
        <>
            <Header />
            <ImageSlider />
            <ScrollElement name="ourSolutions" className="element">
                <OurSolutions />
            </ScrollElement>

            <WhatsNew />
            <Benefits />
            <ScrollElement name="aboutInizio" className="element">
                <AboutInizioNavigator />
            </ScrollElement>

            <Capabilities />
            <ScrollElement name="caseStudies" className="element">
                <CaseStudy />
            </ScrollElement>
            <OurJourney />
            <ScrollElement name="contactUs" className="element">
                <ContactUs />
            </ScrollElement>

            <Footer />
            {showScrollButton && (
                <div className="scroll-up-arrow" onClick={scrollToTop}>
                    <div className="up-arrow-bg">
                        <ArrowUpwardIcon style={{ color: "#fff" }} />
                    </div>
                </div>
            )}
        </>
    );
}

export default HomePage;