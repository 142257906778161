import "./benefits.css";
import DataDriven from "../../../assets/hp-images/data-driven.svg";
import Customisable from "../../../assets/hp-images/customisable.svg";
import Efficient from "../../../assets/hp-images/efficient.svg";
import Secure from "../../../assets/hp-images/secure.svg";
import UnlockingAI from "../../../assets/hp-images/unlocking-ai.svg";

const benefitsData = [
  {
    icon: DataDriven,
    title: "Data Driven:",
    text: "Uses sophisticated data analytics and can access real-time data universe of common datasets, to optimise decision making. (e.g., Clinical Trials & Benchmark Data, Biopharma Internal Data).",
  },
  {
    icon: Customisable,
    title: "Customisable:",
    text: "Provides Custom Analysis suited to specific client data and needs, from imputation and predictive modelling to machine learning, dashboards and insights generation.",
  },
  {
    icon: Efficient,
    title: "Efficient:",
    text: "Leverages a robust cloud-based architecture, ensuring seamless and automated deployment processes.",
  },
  {
    icon: Secure,
    title: "Secure:",
    text: "Robust data security to safeguard your intellectual property, ensuring compliance with industry standards through the platform.",
  },
  {
    icon: UnlockingAI,
    title: "Unlocking AI:",
    text: "Unlocks the value of AI, data mining, analysis, and forecasting, our teams can capitalize on efficiencies and delivering superior outcomes.",
  },
];

function Benefits() {
  return (
    <section className="benefits-module">
      <div className="container">
        <div className="row shadow-md corner--radius">
          <div className="col-lg-5 pl-0">
            <div className="benefits-module-bg-img"></div>
          </div>
          <div className="col-12 col-md-12 col-lg-7 p-4 p-md-5 p-xl-7">
            <div className="row mb-4">
              <div className="col-12">
                <h2 className="text-3xl">Purpose built benefits</h2>
              </div>
            </div>
            <div className="row">
              {benefitsData.map((benefit, index) => (
                <div
                  className={`col-12 col-md-6 ${
                    index % 2 === 0 ? "pr-30" : "pl-30"
                  } pb-60`}
                  key={index}
                >
                  <div className="d-flex align-items-start gap-3">
                    <img src={benefit.icon} alt={benefit.title} className="benefits-icon" />
                    <div className="d-flex flex-column">
                      <p className="benefits-ttl">{benefit.title}</p>
                      <p className="benefits-text">{benefit.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Benefits;
